<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet.heat';
import Swal from "sweetalert2";
import { reqMethods } from '../../../state/helpers';
import axios from "axios";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      map: null,
      heatmapLayer: null,
      incidents: {},
      filterIncidents: {},
      inciTypes: [],
      incidenType: null,
      spanView: false,
      data: {
        zone: null,
        typeincident: null,
      },
      title: "Nouvelle zone sensible",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Nouvelle zone",
          active: true,
        },
      ],
      zone: null,
    };
  },
  mounted() {
    this.initializeMap();
    this.getIncidentType();
  },
  methods: {
    ...reqMethods,
    async initializeMap() {
      this.map = L.map('map').setView([6.1319, 1.2225], 12);
      await this.getAllIncidents();

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: ''
      }).addTo(this.map);

      const drawOptions = {
        position: 'topright',
        draw: {
          polygon: true,
          marker: false,
          polyline: false,
          circle: false,
          circlemarker: false
        }
      };

      this.heatmapLayer = L.heatLayer(
        this.incidents.map(point => [point.lat, point.lng, 10]),
        { radius: 20, maxZoom: 18 }
      ).addTo(this.map);

      const drawnItems = new L.FeatureGroup();
      this.map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw(drawOptions);
      this.map.addControl(drawControl);

      this.map.on(L.Draw.Event.CREATED, event => {
        const layer = event.layer;
        drawnItems.addLayer(layer);

        const latlngs = layer.getLatLngs()[0];
        const polygonData = latlngs.map(latlng => `${latlng.lng} ${latlng.lat}`).join(', ');
        const formattedPolygon = `POLYGON((${polygonData}, ${latlngs[0].lng} ${latlngs[0].lat}))`;
        this.data.zone = formattedPolygon;
      });
    },
    async filter(inciType) {
      this.incidenType = inciType.label;
      this.data.typeincident = inciType.value;
      console.log(this.data.typeincident);
      this.filterIncidents = await this.incidents.filter((incident) => {
        return (
          incident.typInci.includes(inciType.label)
        );
      });

      this.map.removeLayer(this.heatmapLayer);

      this.heatmapLayer = L.heatLayer(
        this.filterIncidents.map(point => [point.lat, point.lng, 10]),
        { radius: 20, maxZoom: 18 }
      ).addTo(this.map);

    },
    getIncidentType() {
      const that = this;
      this.getRequest('/investigation/type-incident/')
        .then(
          function (response) {
            response.data.forEach(element => {
              that.inciTypes.push({ value: element.id, label: element.name });
            });
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    async getAllIncidents() {
      const that = this;
      try {
        const response = await axios.get('https://api.alcit.sims-technologie.com/api/v1/investigation/incident/',
          {
            headers: {
              'Authorization': localStorage.getItem('token'),
            },
          });
        that.incidents = response.data.map(
          (incident) => {
            return {
              id: incident.id,
              lat: incident.location?.coordinates[0],
              lng: incident.location?.coordinates[1],
              typInci: incident.type?.name
            };
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    createDangerArea() {
      console.log(this.data);
      this.spanView = true;
      const that = this;
      this.postRequest({
        route: '/dangerarea/dangerarea/',
        data: this.data
      }).then(
        function (response) {
          that.spanView = false;
          console.log(response);
          Swal.fire('Success', "La zone à été Ajouté!", "success");
        },
        function (error) {
          that.spanView = false;
          console.log(error);
          Swal.fire({
            title: "Oops...",
            text: "Erreur lors de l'ajout de la Contravention",
            icon: "error",
            confirmButtonClass: "btn btn-primary w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      )
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Sélectionnez une nouvelle zone</h4>
            <form>
              <div class="row">
                <div class="col-lg-6 mt-1">
                  <h5 class="d-inline">
                    Type incident:
                  </h5>
                  <p v-if="incidenType == null" class="text-info d-inline">
                    Aucun
                  </p>
                  <p class="text-info d-inline">
                    {{ incidenType }}
                  </p>
                </div>
                <div class="col-lg-6 mt-1">
                  <b-dropdown split text="Filtrer incidents" variant="info" class="float-end mb-3">
                    <b-dropdown-item v-for="incident in inciTypes" :key="incident.id" @click="filter(incident)"> {{
                      incident.label }} </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="row mb-4">
                <!-- <label class="col-form-label col-lg-2">Délimitez la zone</label> -->
                <div class="col-lg-12">
                  <div id="map" style="height: 600px;"></div>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Nom</label>
                <div class="col-lg-10">
                  <input id="code" v-model="data.name" name="code" type="text" class="form-control"
                    placeholder="Renseigner le nom de la zone ..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Message</label>
                <div class="col-lg-10">
                  <textarea v-model="data.message" id="projectdesc" class="form-control" rows="4"
                    placeholder="Message de sécurité ..."></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="reset" class="btn btn-primary">
                    Annuler
                  </button>
                  <button @click="createDangerArea()" type="button" class="btn btn-success" style="float: right"  :disabled="spanView">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Enregistrer la zone
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>